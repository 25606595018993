<template>
	<!--begin::Content-->
	<div class="flex-grow-1" style="margin-bottom: 26px" v-if="!adminSiteLoading && adminSite.showStarlinkSubscription && dataLoaded">
		<!--begin::Card-->
		<div class="card card-custom"  v-if="dataLoaded">
			<!--begin::Header-->
			<div class="card-header py-3">
				<div class="card-title align-items-start flex-column">
					<h3 class="card-label font-weight-bolder text-dark">Starlink Monthly Subscription</h3>
					<span class="text-muted font-weight-bold font-size-sm mt-1"
						>Note: informational only. Traffic NOT stopped or controlled by the portal.</span
					>
				</div>
				<div class="card-toolbar">
					<button
						type="reset"
						class="btn btn-success mr-2"
						@click="saveQuota()"
						ref="kt_save_starlink_changes"
					>
						Save Changes
					</button>
					<button
						type="reset"
						class="btn btn-secondary"
						v-show="adminSite.starlinkQuotaStatus === 'addingStarlinkQuota'"
						@click.prevent="cancel"
					>
						Cancel
					</button>
				</div>
			</div>
			<!--end::Header-->
			<!--begin::Form-->
			<b-form class="form" v-if="dataLoaded">
				<div class="card-body" >
					<!--begin::Form Group-->
					<b-form-group
					label-cols-lg="3"
					label="Current Billing Period"
					label-class="font-weight-bold pt-0 h4"
					class="mb-0"
					>
						<div v-for="(item, key) in quotas" :key="key">
							<!-- <div class="d-flex justify-content-between align-items-center"> -->
							<div class="form-group row">
								<label class="col-xl-3 col-lg-3 col-form-label" style="text-align: right;" ></label>
								<div class="col-lg-9 col-xl-6 d-flex justify-content-between">
									<div>Service Line: {{ key }}</div>
									<div class="input-group-append">
									
									<input type="checkbox" id="checkboxInclude" value="true" v-model="quotas[key]['include']">
									<label for="checkboxInclude" class="ml-1 mb-0">include</label>
								
								</div>
								</div>
							</div>

							<!-- BEGIN DATE 1 -->
							<div class="form-group row">
								<label class="col-xl-3 col-lg-3 col-form-label" style="text-align: right;" for="datepicker-start">Start date:</label>
								<div class="col-lg-9 col-xl-6">
									<div class="form-control form-control-lg form-control-solid">{{ item.startDate.substring(0, 10) }}</div>
								</div>
							</div>
							<!-- END DATE 1 -->
							<!-- BEGIN DATE 2 -->
							<div class="form-group row">
								<label class="col-xl-3 col-lg-3 col-form-label" style="text-align: right;" for="datepicker-end">End date:</label>
								<div class="col-lg-9 col-xl-6">
									<div class="form-control form-control-lg form-control-solid">{{ item.endDate.substring(0, 10) }}</div>
								</div>
							</div>
							<!-- END DATE 2 -->
							<div class="form-group row">
								<label class="col-xl-3 col-lg-3 col-form-label" style="text-align: right;">Monthly Subscription Quota:</label>
								<div class="col-lg-9 col-xl-6">
									<div style="display: flex">
										<div
											class="form-control form-control-lg form-control-solid"
										>{{ item.quotaGigaBytes }} GB</div>
									</div>
								</div>
							</div>
						</div>

						<!--begin::Form Group-->
						<div class="separator separator-dashed my-5"></div>

						<!--begin::Form Group-->
						<div class="form-group row">
							<label class="col-xl-3 col-lg-3 col-form-label" style="text-align: right;">Warning Percentage</label>
							<div class="col-lg-9 col-xl-6">
								<div style="display: flex">
									<div class="input-group-append mr-2">
										<div class="input-group-text">
										<input type="checkbox" id="checkbox1" value="1" v-model="warn50">
										<label for="checkbox1" class="ml-1 mb-0">50%</label>
										</div>
									</div>
									<div class="input-group-append mr-2">
										<div class="input-group-text">
										<input type="checkbox" id="checkbox2" value="1" v-model="warn75">
										<label for="checkbox2" class="ml-1 mb-0">75%</label>
										</div>
									</div>
									<div class="input-group-append">
										<div class="input-group-text">
										<input type="checkbox" id="checkbox3" value="1" v-model="warn90">
										<label for="checkbox3" class="ml-1 mb-0">90%</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					
						<!--begin::Form Group-->
						<div class="separator separator-dashed my-5"></div>

						<!--begin::Form Group-->
						<div class="form-group row">
							<label class="col-xl-3 col-lg-3 col-form-label"  style="text-align: right; vertical-align: middle;">Email</label>
							<div class="col-lg-9 col-xl-6">
								<div class="form-group">
									<label class="form__label label-heading" for="textarea-default">
										<div>Emails to receive warning alerts:</div>
									</label>
									<b-form-input
										id="input-1"
										type="email"
										placeholder="Enter email"
										required
										v-model="email"
									></b-form-input>
									<span class="text-muted font-weight-bold font-size-md mt-3">
										( separate emails with a semicolon ; )
									</span>
								</div>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-xl-3 col-lg-3 col-form-label"  style="text-align: right; vertical-align: middle;">Email BCC</label>
							<div class="col-lg-9 col-xl-6">
								<div class="form-group">
									<label class="form__label label-heading" for="textarea-default">
										<div>BCC emails to receive warning alerts:</div>
									</label>
									<b-form-input
										id="input-1"
										type="email"
										placeholder="Enter email"
										required
										v-model="bccEmail"
									></b-form-input>
									<span class="text-muted font-weight-bold font-size-md mt-3">
										( separate emails with a semicolon ; )
									</span>
								</div>
							</div>
						</div>

					</b-form-group>

				</div> <!-- end: Card-Body -->
			</b-form>
			<!--end::Form-->

		</div>
		<!--end::Card-->

	</div>
	<!--end::Content-->
</template>

<script>
import { mapGetters } from 'vuex';
import { REMOVE_STARLINK_QUOTA, UPDATE_STARLINK_SUBSCRIPTION } from '@/core/services/store/admin.module';

export default {
	name: 'SiteStarlinkSubscription',
	data() {
		return {
			dataLoaded: false,
			loaded: false,
			subscriptionLimit: 0,
			warn50: 0,
			warn75: 0,
			warn90: 0,
			warningPercent: 90,
			email: '',
			bccEmail: '',
			status: '',
			advancedStarlinkConfig: {},
			advancedStarlinkSaved: false,
			quotas: {},
			advancedQuotaConfig: {},
			quotaGigaBytes: '',
			totalUsedGigaBytes: ''
		};
	},
	computed: {
		...mapGetters(['adminSite', 'adminSiteLoading']),
		adminSiteStarlinkQuotaStatus() {
			return this.adminSite.starlinkQuotaStatus;
		}
	},
	methods: {
		getStarlinkSubscriptions() {
			let serviceLines = [];
			this.adminSite.data.devices.map(device => {
				if (device.TypeId === 23) {
				const source = JSON.parse(device.Source);
				serviceLines.push(source.serviceLineNumber);
				}
			});
			if (serviceLines.length > 0) {
				const uniqueServiceLines = [...new Set(serviceLines)];
				uniqueServiceLines.forEach((serviceline) => {
					if(serviceline in this.quotas) {
						console.log('SL was in quotas');
					} else {
						this.$http.get(`site/${this.adminSite.id}/starlink/usage/${serviceline}`).then((res) => {
							let sub = res.data.currentBillingPeriod;
							sub['include'] = false;
							this.$set(this.quotas, serviceline, sub);
							this.$set(this.advancedQuotaConfig, serviceline, true);
						});
					}
				})
			};			
		},
		loadSavedQuotas() {
			this.quotas = {};
			this.warn50 = 0;
			this.warn75 = 0;
			this.warn90 = 0;
			this.email = '';
			this.bccEmail = '';
			const starlinkQuotas = this.adminSite.data.starlinkQuotas;
			if (starlinkQuotas) {
				starlinkQuotas.map((q) => {
					const updatedQ = Object.assign({}, q, { include: true });
					this.$set(this.quotas, q.serviceline, updatedQ);
					this.warn50 = q.warn50;
					this.warn75 = q.warn75;
					this.warn90 = q.warn90;
					this.email = q.email;
					this.bccEmail = q.bccEmail;
				})
			}
			this.dataLoaded = true;
			
		},
		saveQuota() {
			let data = [];
			for(let key in this.quotas) {
					let tempdata = {
						serviceline: key,
						siteId: this.adminSite.id,
						quotaGigaBytes: this.quotas[key].quotaGigaBytes,
						startDate: this.quotas[key].startDate,
						endDate: this.quotas[key].endDate,
						email: this.email,
						bccEmail: this.bccEmail,
						warn50: this.warn50,
						warn75: this.warn75,
						warn90: this.warn90,
						include: this.quotas[key].include
					};
					data.push(tempdata);
			}
			
			// set spinner to submit button
			const submitButton = this.$refs['kt_save_starlink_changes'];
			submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

			this.$http.post(`site/${this.adminSite.id}/starlink/quota_alert/save`, data).then((res) => {
				//to be added: error handling
				submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
			});
		},
		deleteQuota(quotaId, serviceline) {
			this.$http.post(`site/${this.adminSite.id}/starlink/quota_alert/delete`, { quotaId: quotaId }).then((res) => {
				console.log('deleted: ', res);
				if(res.status === 200) {
					this.$delete(this.quotas, serviceline);
					if(Object.entries(this.quotas).length === 0) {
						this.warn50 = 0;
						this.warn75 = 0;
						this.warn90 = 0;
						this.email = '';
						this.bccEmail = '';
					}
				}
			});
		},
		cancel() {
			this.$store.commit(REMOVE_STARLINK_QUOTA);
		},
	},
	watch: {
		adminSiteLoading(loading) {
			if (!loading) {
				console.log('loading quotas')
				this.loadSavedQuotas();
				this.getStarlinkSubscriptions();
			}
		},
		adminSiteStarlinkQuotaStatus(nVal, oVal) {
			if(nVal==="addingStarlinkQuota") {
				console.log('get Starlink Subscriptions {{watcher}}')
				this.getStarlinkSubscriptions();
			}
		}
	},
	created(){
		console.log('created!')
		// this.loadSavedQuotas();
	},
	beforeMounted(){
		
	},
	mounted() {
		console.log('mounted!')
		this.loadSavedQuotas();
		
	}
};
</script>

<style scoped></style>
